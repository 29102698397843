<template>
  <div class="setting-item s-resource-group">
    <esmp-select
      v-model="localSetting.value"
      :placeholder="localSetting.name"
      @on-select="getRoles"
      filterable
    >
      <esmp-select-option
        v-for="item in resources"
        :value="item.name"
        :key="item.id"
      >
        {{ item.name }}
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import Hub from '@/plugins/event-hub';

export default {
  name: 'SResourceGroup',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      roles: [],
      resources: window.HM_RESOURCES || [],
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getRoles(group) {
      return this.$API.resource.getRoles(group.value).then(({ data }) => {
        this.roles = data;
        Hub.$emit('set-resource-values', {
          id: this.id,
          values: data,
        });
      });
    },
  },
  created() {
    if (!window.HM_RESOURCES) {
      this.$API.resource.getResources().then(({ data }) => {
        window.HM_RESOURCES = data;
        this.resources = data;
      });
    }
  },
};
</script>
